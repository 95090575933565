import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-items">
          <h3>Solutions</h3>
          <Link to="/solutions">How it works</Link>
        </div>
        <div className="footer-link-items">
          <h3>Company</h3>
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
      <div className="website-rights-wrap">
        <small className="website-rights"><Link to="/">IS.ENERGY</Link> © 2021</small>
      </div>
    </div>
  );
};

export default Footer;
