import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-long-y.png'
import { FaBars } from "react-icons/fa"
import { IoClose } from "react-icons/io5"


const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const showMenu = () => setOpenMenu(!openMenu);

    return (
       <>
      <div className='navbar'>
          <Link to='/' className='navbar-logo'>
            <img src={logo} className='logo' alt={'logo'}/>
          </Link>
          <div className='menu-icon' onClick={showMenu}>
            {openMenu ? <IoClose size={50}/> : <FaBars size={40}/>}
          </div>

          <nav className={openMenu ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showMenu}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={showMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={showMenu}
              >
                About
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/solutions'
                className='nav-links'
                onClick={showMenu}
              >
                Solutions
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact'
                className='nav-links'
                onClick={showMenu}
              >
                Contact
              </Link>
            </li>
            <li className='nav-item'>
              <Link to={{ pathname: "https://www.impossiblesensing.com/" }} target="_blank" >
                Affiliate
              </Link>
            </li>
          </ul> 
          </nav>
      </div>
    </>
    )
}

export default NavBar

