import React from 'react'

const ContactInfoItem = ({icon,text}) => {
    return (
        <div className='contact-item'>
            <div className="icon">{icon}</div>
            <div className="info">
              <div>{text}</div>
            </div>
        </div>
    )
}

export default ContactInfoItem
