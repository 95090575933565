import React, { useState } from "react";
import flow from "../assets/images/flow.png";
import Airys from "../assets/images/Airys.png";
import SolutionsVideo from "./SolutionsVideo";
import PopupVideo from "./PopupVideo";

const Description = () => {
  const [shown, setShown] = useState(false);

  return (
    <div className="solutions-container">

      <div className="solution-container">

        <div className="description">
          <div className="solution-left">
            <div className="solution-titles">
              <h3 className="solution-title">FLOW</h3>
              <h4 className="solution-subtitle">At-well multi-phase flow meter
 increases production visibility while reducing emissions</h4>
            </div>
            <div>
            <p>
              Our first product, FLOW, is a green-tech solution that will enable
              producers to extract cleaner oil and gas while capturing economic
              benefits.
            </p>
            <br/>
            <p>
              FLOW is an optical imaging solution for individual wellheads that
              reports real-time mass and volumetric flow rates of oil, water,
              and gas phases in multi-phase flows. FLOW is specifically tailored
              to the problem at hand: obtain accurate flow compositions in
              individual wells with high-watercut flows, high gas-content flows,
              and variable pressure and flow types; do this cost-effectively,
              even in stripper wells; and ultimately replace the test-separator.Backed by our patent-pending technology, developed for NASA and the U.S. Department of Energy, FLOW finally breaks the
              cost-performance barrier of multi-phase flowmeters.
            </p>
            </div>
            <div className="info-buttons">
              <button className="specs" onClick={() => setShown(!shown)}>
                LEARN MORE{" "}
              </button>
            </div>
            {shown ? (
              <PopupVideo onClick={() => setShown(!shown)}>
                <SolutionsVideo embedId="wANuHEqdxgs" />
              </PopupVideo>
            ) : null}
            {/* {shown ? <SolutionsVideo embedId="wANuHEqdxgs"/> : null} */}
          </div>
          <div className="solution-image">
            <img src={flow} width="100%" alt=''/>
            {/* <SolutionsVideo embedId="wANuHEqdxgs" /> */}
          </div>
        </div>
      </div>

      <div className="solution-container">
        <div className="separator"></div>
        <div className="description">
          <div className="solution-left">
            <div className="solution-titles">
            <h3 className="solution-title">AIRYS</h3>
            <h4 className="solution-subtitle">(Aerostat In-site Remote Yoyo Scanner)</h4>
            </div>
            <p>
              AIRYS is an optical sensing technology and data analytics system
              that discovers and quantifies fugitive emissions. Engineered to
              close today’s measuring gaps through capturing high-resolution
              data, in real time, at the source. AIRYS builds on space
              exploration technology transferred to GHG measurements and it is
              designed to be an autonomous cost-effective solution.
            </p>
          </div>
          <div className="solution-image airys">
            <img src={Airys} width="100%" alt=''/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
