import React from 'react';

const SolutionDescription = ({ descriptions }) => {
	return (
		<div className='description-container'>
			{descriptions.map((description, index) => {
				const color = index % 2 === 0 ? 'companyColor' : '';
				return (
					<div className={`description-item ${color}`}>
						{description.icon}
						{description.description}
					</div>
				);
			})}
		</div>
	);
};

export default SolutionDescription;
