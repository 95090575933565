import React from 'react';

const SolutionTitle = ({ title, subtitle }) => {
	return (
		<div className='section-solution-title'>
			<h2>{title}</h2>
			<h3>{subtitle}</h3>
		</div>
	);
};

export default SolutionTitle;
