import React from 'react';
import { Link } from 'react-router-dom';
import { flowDescriptions, airysDescriptions, badgerDescriptions } from './utils';
import SolutionTitle from './SolutionTitle';
import SolutionDescription from './SolutionDescription';

const Home = () => {
	return (
		<div className='home'>
			<header className='header'>
				<div className='home-title'>
					<h1>From space exploration to earth: climate-smart solutions for cleaner energy</h1>
				</div>
			</header>

			<div className='home-content'>
				<div className='section-container aboutSection'></div>

				<div className='section-container flowSection'>
					<SolutionTitle
						title='FLOW'
						subtitle='At-well multi-phase flow meter increases production visibility while reducing emissions'
					/>
					<SolutionDescription descriptions={flowDescriptions} />
				</div>

				<div className='section-container airysSection'>
					<SolutionTitle
						title='AIRYS'
						subtitle='Airborne remote-sensing tools discover and quantify fugitive emissions at scale'
					/>
					<SolutionDescription descriptions={airysDescriptions} />
				</div>

				<div className='section-container badgerSection'>
					<SolutionTitle
						title='BADGER'
						subtitle='Scalability, timely, and cost-effective measurements of carbon in soil sequestration projects'
					/>
					<SolutionDescription descriptions={badgerDescriptions} />
				</div>
				<div className='section-container'>
					<SolutionTitle title='COMPANY' subtitle='' />
					<div className='section-company'>
						<p>
							At is.energy we work with our customers in the oil & gas industry to identify critical
							data and measurement gaps and introduce industry-first solutions to close these gaps.
						</p>
						<p>
							Our solutions build on pioneering optical sensing technologies used in space
							exploration that deliver unprecedented sensitivity and reliability.
						</p>
						<p>
							We are an affiliate company of{' '}
							<a href='https://www.impossiblesensing.com/' target='_blank' rel='noreferrer'>
								Impossible Sensing
							</a>
							, a US based firm that creates leading-edge technologies to find life and resources on
							every planet and ocean in the solar system.
						</p>
						<p>
							At is.energy we are now turning these innovations into solutions for producers to
							deliver cleaner oil and gas while capturing economic benefits.
						</p>
						<p>
							<Link to='/contact'>Contact us</Link> to learn more.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
