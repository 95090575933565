import bulb from '../assets/images/bulb.svg';
import dial from '../assets/images/dial.svg';
import leaf from '../assets/images/leaf.svg';
import notepad from '../assets/images/notepad.svg';
import shield from '../assets/images/shield.svg';
import shieldWhite from '../assets/images/Shield-white.svg';
import gauge from '../assets/images/gauge.svg';
import Kite from '../assets/images/Kite.svg';
import Cloud from '../assets/images/Cloud.svg';
import AI from '../assets/images/AI.svg';
import Performance from '../assets/images/Performance.svg';
import trophy from '../assets/images/trophy.svg';

export const flowDescriptions = [
	{
		icon: <img src={bulb} alt={''} />,
		description:
			'Patent-pending optical imaging technology adapted from instruments we’ve delivered to NASA to find life (complex hydrocarbons) on Mars',
	},
	{
		icon: <img src={dial} alt={''} />,
		description:
			'Direct real-time measurement of watercut and molecular composition of oil and gas phases in multi-phase flows',
	},
	{
		icon: <img src={notepad} alt={''} />,
		description:
			'Non-intrusive, flow regime independent, works across the entire fraction range of oil, water, and gas without the need for calibration',
	},
	{
		icon: <img src={leaf} alt={''} />,
		description:
			'Replaces test separators with an in-line device that has the footprint of a suitcase and zero emissions',
	},
	{
		icon: <img src={shield} alt={''} />,
		description:
			'Removes the need to deploy personnel and stop production to measure by automating data collection, analysis, and reporting',
	},
];
export const airysDescriptions = [
	{
		icon: <img src={bulb} alt={''} />,
		description:
			'Patent-pending combination of imaging and laser-based spectroscopy adapted from orbital technologies we are developing for NASA',
	},
	{
		icon: <img src={gauge} alt={''} />,
		description:
			'Detects and quantifies methane and other GHG emissions fluxes autonomously in user-specified monitoring periods',
	},
	{
		icon: <img src={Kite} alt={''} />,
		description:
			'Continuous and real-time remote measurements from unmanned aerostats and stratospheric unmanned aerial systems (UAS)',
	},
	{
		icon: <img src={shieldWhite} alt={''} />,
		description:
			'Removes the need to deploy personnel to pilot drones or collect and analyze air samples',
	},
	{
		icon: <img src={Cloud} alt={''} />,
		description:
			'Removes the need for interval-based inspections; AIRYS generates automatic reports to operators who can quickly mitigate venting',
	},
];
export const badgerDescriptions = [
	{
		icon: <img src={bulb} alt={''} />,
		description:
			'Patent-pending, on-site monitoring, accounting, and verification of soil carbon service developed for the U.S. Department or Energy ',
	},
	{
		icon: <img src={AI} alt={''} />,
		description:
			'Replaces costly and logistically problematic soil coring and off-site analysis with an on-site robotic service',
	},
	{
		icon: <img src={Performance} alt={''} />,
		description:
			'Verify increases in soil carbon across a geographic area reliably and continuously throughout the crediting period ',
	},
	{
		icon: <img src={trophy} alt={''} />,
		description:
			'Confidence in quality of soil carbon sequestration projects through reliable, high frequency and spatially dense soil carbon measurements',
	},
];
