import React from 'react'
import { MdEmail, MdPlace } from 'react-icons/md';
import ContactInfoItem from './ContactInfoItem';

const Contact = () => {
    
  return (
    <div className="contact">
      <h2>Contact Us</h2>
          <div className="left">
            <ContactInfoItem icon={<MdEmail size={40}/>} text="info@isenergy.ca" />
            <ContactInfoItem icon={<MdPlace size={40}/>} text="Calgary, AB - Canada" />
          </div>
          </div>
  );
}

export default Contact
