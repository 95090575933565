import './App.css';
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import Solutions from './components/Solutions'
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet-async';

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Helmet>
            <title>Impossible Sensing Energy | Calgary,AB</title>
            <meta
                name="description"
                content="Impossible Sensing Energy brings cutting-edge optical sensors to the energy sector"
                />
            <meta
                name="keywords"
                content="Impossible, Sensing, Energy, MPFM, Multiphase, Multi-Phase, Methane detection, GHG, Core-less logging, Space Technology, Cleaner energy, Optical Sensors"
                />
            <link rel='canonical' href='/' />
        </Helmet>
        <ScrollToTop >
        <Switch>
          <Route path='/' exact>
            <Home/>
          </Route>
          <Route path='/about'>
            <About/>
          </Route>
          <Route path='/solutions'>
            <Solutions/>
          </Route>
          <Route path='/contact'>
            <Contact/>
          </Route>
        </Switch>
        </ScrollToTop>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
