import React from 'react'
import { IoClose } from "react-icons/io5"

const PopupVideo = (props) => {
    return (
        <div className="modalBackground">
            <div className="popup">
                <div className="popup-inner">
                    <div className='close-btn' onClick={props.onClick}>
                        <IoClose size={40}/> 
                    </div>
                    {/* <button className='close-btn' onClick={props.onClick}>Close</button> */}
                    {props.children}
                </div>
            </div>
        </div>
    ) 
}

export default PopupVideo
