import React from 'react'
import { Helmet } from 'react-helmet-async'
import Description from './Description'

const Solutions = () => {
    return (
        <div className='solutions-page'>
            <Helmet>
                <title>Solutions | Impossible Sensing Energy</title>
                <meta name="description" content="Impossible Sensing Energy brings cutting-edge optical sensors made in Calgary, AB"/>
                <link rel='canonical' href='/solutions'/>
            </Helmet>
          <section className='solutions' >
                <h2>SOLUTIONS</h2>
                <Description />
            </section>   
        </div>
    )
}

export default Solutions
