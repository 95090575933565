import React from 'react'

const SolutionsVideo = ({ embedId }) => {
    return (
        <div className="video-responsive">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      autoplay
      auto
    />
  </div>
    )
}

export default SolutionsVideo
