import React from 'react'
import { Helmet } from 'react-helmet-async'


const About = () => {
    return (
        <div >
            <Helmet>
                <title>About Us | Impossible Sensing Energy</title>
                <meta name="description" content="Impossible Sensing Energy brings cutting-edge optical sensors made in Calgary, AB"/>
                <link rel='canonical' href='/about'/>
            </Helmet>
            <section className='page-container about' >
                <h2>ABOUT US - VISION </h2>
                        <div className="about-picture"></div>
                        <div className="about-content">
                            <p>We pursue creative, ground breaking ideas that arise out of curiosity and observation. We explore these ideas with an open mind, always seeking to discover what others think is impossible.</p>
                        </div>
            </section>
        </div>
    )
}

export default About
